import './App.css';
import Navbar from './Navbar';
import Footer from './footer';
import puntored from './assets/puntosred.jpg';
const Puntosred =() =>{
    return <section>
      
        <Navbar />
        <br />
        <br />
        <br />
        <br/>
          <br/>
        <div className='servicios2'>
          <div className="nosotros-container">
            <div className="mosaico">
              <div className="fila">
                <div className="imagen-container">
                  <img src={puntored} alt="Intregracionredes" className="fila-img" style={{ maxWidth: '580px', maxHeight: '580px', borderRadius:'35px' }} />
                </div>
              </div>
            </div>
          </div>
          
          <div className="texto">
          <h2 style={{ marginLeft: '45px' }}>Mantenimientos Preventivos y Correctivos</h2>
          <hr />
          <p className='p'>
          No esperamos a que algo falle. Establecemos programas de mantenimiento preventivo y correctivos para mantener tu red en su mejor forma. Y si surge algún problema, actuamos con la mayor velocidad posible. Tu tranquilidad es nuestra prioridad.
          </p>
          
        </div>
        </div>
        <br/><br/>
        <Footer />
      </section>
    
  }
  
  export default Puntosred;
  